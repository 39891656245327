import logo from '../assets/images/crown_sm.png'

export default function Footer() {
  return (
    <footer className='bg-black w-full flex flex-col justify-center items-center py-4'>
      <div className='flex flex-col justify-center items-center font-bold border-b-[1px] border-yellow-50'>
        <div className='w-20 sm:w-24'>
          <img
            className='w-full h-full object-cover'
            alt='logoImg'
            src={logo}
          />
        </div>
        <h1 className='gold text-3xl'>YP</h1>
        <h1 className='gold'>YOUPRINCESS</h1>
      </div>
      <div className='p-2'>
        <h1 className='text-yellow-100 text-center text-xs'>
          Агентство YOUPRINCESS не предоставляет услуги интимного характера. Все
          представленные вакансии, текст и изображения являются собственностью
          работодателя. Только для девушек от 18 лет. Сопровождение для мужчин
          без интима
        </h1>
      </div>
    </footer>
  )
}
