import { benefitsGrid } from '../utils/benefitsGrid'
import SwitchImage from '../hooks/imageSwitcher'
import logo from '../assets/images/crown_sm.png'
import { Link } from 'react-router-dom'

export default function BenefitsGrid() {
  const data = benefitsGrid

  return (
    <section className='w-full h-full flex flex-col justify-center items-center py-12'>
      <div className='flex flex-col items-center justify-center gap-2 w-full px-2 pb-8 font-bold'>
        <div className='w-12'>
          <img
            className='w-full h-full object-cover'
            alt='logoImg'
            src={logo}
          />
        </div>
        <h5 className='gold'>YOUPRINCESS</h5>
        <h1 className='text-3xl font-medium drop-shadow-lg text-center text-yellow-50'>
          ПРЕИМУЩЕСТВА РАБОТЫ С НАМИ
        </h1>
      </div>
      <div className='grid xl:grid-cols-2 gap-2 sm:gap-4 px-2 w-full sm:w-5/6'>
        {data.map((card, idx) => (
          <div
            key={idx}
            className='relative w-full h-[200px] sm:h-[500px] shadow-lg border-4 border-yellow-50'
          >
            <SwitchImage
              style={`w-full h-full object-cover bg-black`}
              mobile={card.mobileImage}
              desktop={card.desktopImage}
            />
            <div className='absolute inset-0 bg-gradient-to-t from-white/10 via-black/70 to-white/10'></div>
            <div className='absolute inset-0 flex flex-col justify-center items-center px-2'>
              <h1 className='text-yellow-50 text-2xl sm:text-3xl font-bold drop-shadow-md text-center'>
                {card.title}
              </h1>
              <h1 className='text-yellow-50 w-full lg:w-1/2 text-sm sm:text-md text-center'>
                {card.text}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <div className='flex flex-col items-center justify-center gap-4 py-6'>
        <h1 className='text-yellow-50 text-center text-sm lg:text-md'>
          ЕСЛИ У ВАС ОСТАЛИСЬ КАКИЕ-ТО ВОПРОСЫ - Я С РАДОСТЬЮ НА НИХ ОТВЕЧУ
        </h1>
        <div className='flex flex-col sm:flex-row items-center justify-center gap-4'>
          <h1 className='pt-2'>
            <Link
              to={'https://t.me/SerbianWork'}
              className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
            >
              Наш Telegram
            </Link>
          </h1>
          <h1 className='pt-2'>
            <Link
              to={
                'https://api.whatsapp.com/send/?phone=%2B79810287364&text=%D0%AF+%D0%BE%D1%82+YOUPRINCESS.+%D0%A5%D0%BE%D1%87%D1%83+%D0%BA+%D0%92%D0%B0%D0%BC+%D0%B2+%D1%82%D1%83%D1%80&type=phone_number&app_absent=0'
              }
              className='w-3/4 goldbg rounded-full px-4 py-2  font-bold'
            >
              Наш Whatsapp
            </Link>
          </h1>
        </div>
      </div>
    </section>
  )
}
