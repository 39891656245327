import desktop from '../assets/videos/desktopLastBanner.mp4'
import SwitchVideo from '../hooks/videoSwitcher'
import mobile from '../assets/videos/lastMobile.mp4'
import logo from '../assets/images/crown_sm.png'
import { Link } from 'react-router-dom'

export default function LastVideoBanner() {
  return (
    <section className='w-full h-screen'>
      <div className='w-full h-full relative'>
        <div className='absolute inset-0 bg-gradient-to-t from-black via-white/10 to-black'></div>
        <SwitchVideo
          mobile={mobile}
          desktop={desktop}
        />
        <div className='absolute inset-0 flex flex-col items-center justify-center gap-2 w-full px-2 pb-8 font-bold'>
          <div className='w-12'>
            <img
              className='w-full h-full object-cover'
              alt='logoImg'
              src={logo}
            />
          </div>
          <h5 className='gold'>YOUPRINCESS</h5>
          <h1 className='text-3xl font-medium drop-shadow-lg text-center text-yellow-50'>
            НАШИ ТРЕБОВАНИЯ К МОДЕЛИ
          </h1>
          <div className='flex flex-col justify-center items-center text-center font-bold text-sm sm:text-2xl lg:text-2xl text-yellow-50 gap-2 md:gap-4 px-4 pt-2 sm:py-12'>
            <h1 className='drop-shadow-lg flex flex-col items-center'>
              <span className='pb-2'>
                <img
                  className='w-6 h-full object-cover'
                  alt='logoImg'
                  src={logo}
                />
              </span>
              Приятная внешность и ухоженность
            </h1>
            <h1 className='drop-shadow-lg flex flex-col items-center'>
              <span className='pb-2'>
                <img
                  className='w-6 h-full object-cover'
                  alt='logoImg'
                  src={logo}
                />
              </span>
              Возраст от 18 и до… мы не регламентируем
            </h1>
            <h1 className='drop-shadow-lg flex flex-col items-center'>
              <span className='pb-2'>
                <img
                  className='w-6 h-full object-cover'
                  alt='logoImg'
                  src={logo}
                />
              </span>
              Отсутствие нарко/алко зависимостей
            </h1>
            <h1 className='drop-shadow-lg flex flex-col items-center'>
              <span className='pb-2'>
                <img
                  className='w-6 h-full object-cover'
                  alt='logoImg'
                  src={logo}
                />
              </span>
              Наличие проф фото не обязательно, но будет плюсом можно работать
              по типажам
            </h1>
            <h1 className='drop-shadow-lg flex flex-col items-center'>
              <span className='pb-2'>
                <img
                  className='w-6 h-full object-cover'
                  alt='logoImg'
                  src={logo}
                />
              </span>
              МБР является допом, но если он входит в сервис, то это огромный
              плюс и увеличит поток гостей
            </h1>
            <h1 className='drop-shadow-lg flex flex-col items-center pb-2 sm:pb-4'>
              <span className='pb-2'>
                <img
                  className='w-6 h-full object-cover'
                  alt='logoImg'
                  src={logo}
                />
              </span>
              Знание английского языка приветствуется
            </h1>
            <div className='flex flex-col sm:flex-row items-center justify-center gap-4 text-sm'>
              <h1 className='pt-2'>
                <Link
                  to={'https://t.me/SerbianWork'}
                  className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
                >
                  Задайте вопросы в Телеграм
                </Link>
              </h1>
              <h1 className='pt-2'>
                <Link
                  to={
                    'https://api.whatsapp.com/send/?phone=%2B79810287364&text=%D0%AF+%D0%BE%D1%82+YOUPRINCESS.+%D0%A5%D0%BE%D1%87%D1%83+%D0%BA+%D0%92%D0%B0%D0%BC+%D0%B2+%D1%82%D1%83%D1%80&type=phone_number&app_absent=0'
                  }
                  className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
                >
                  Задайте вопросы в Whatsapp
                </Link>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
