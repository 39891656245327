import BenefitsGrid from './components/BenefitsGrid'
import BigHeroText from './components/BigHeroText'
import HeroSection from './components/HeroSection'
import HeroVideo from './components/HeroVideo'
import HowToBanner from './components/HowToBanner'
// import LastBanner from './components/LastBanner'
import LastVideoBanner from './components/LastVideoBanner'
import RenderOnViewportEntry from './hooks/useRenderViewport'

function App() {
  return (
    <div className='App'>
      <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <HeroVideo />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <HeroSection />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <BigHeroText />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <BenefitsGrid />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <HowToBanner />
      </RenderOnViewportEntry>
      {/* <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <LastBanner />
      </RenderOnViewportEntry> */}
      <RenderOnViewportEntry
        threshold={0.25}
        className='h-full bg-black'
        style={{ minHeight: '240px' }}
      >
        <LastVideoBanner />
      </RenderOnViewportEntry>
    </div>
  )
}

export default App
