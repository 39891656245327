import { Link } from 'react-router-dom'
import logo from '../assets/images/crown_sm.png'

export default function MainBanner() {
  return (
    <div className='absolute inset-0 flex flex-col justify-center items-center dont-bold'>
      <div className='flex flex-col justify-center items-center w-1/2 sm:w-1/5 drop-shadow-lg sm:text-8xl font-bold bg-gradient-to-r from-transparent via-neutral-600 to-transparent'>
        <div className='w-2/3'>
          <img
            className='w-full h-full object-cover'
            alt='logoImg'
            src={logo}
          />
        </div>
        <h1 className='text-7xl sm:text-9xl gold'>YP</h1>
        <h1 className='text-3xl gold'>YOUPRINCESS</h1>
      </div>
      <h1 className='text-4xl md:text-5xl lg:text-7xl gold mt-12 font-bold'>
        ЭСКОРТ СЕРБИИ #1
      </h1>
      <div className='flex flex-col sm:flex-row items-center justify-center gap-6 sm:gap-4 pt-6'>
        <h1>
          <Link
            to={'https://t.me/SerbianWork'}
            className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
          >
            Наш Telegram
          </Link>
        </h1>
        <h1>
          <Link
            to={
              'https://api.whatsapp.com/send/?phone=%2B79810287364&text=%D0%AF+%D0%BE%D1%82+YOUPRINCESS.+%D0%A5%D0%BE%D1%87%D1%83+%D0%BA+%D0%92%D0%B0%D0%BC+%D0%B2+%D1%82%D1%83%D1%80&type=phone_number&app_absent=0'
            }
            className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
          >
            Наш Whatsapp
          </Link>
        </h1>
      </div>
    </div>
  )
}
