import SwitchVideo from '../hooks/videoSwitcher'
import mobile from '../assets/videos/benefitsMobile.mp4'
import desktop from '../assets/videos/benefitsDesktop.mp4'
import { Link } from 'react-router-dom'
import logo from '../assets/images/crown_sm.png'

export default function BigHeroText() {
  return (
    <section className='w-full h-screen'>
      <div className='w-full h-full relative'>
        <div className='absolute inset-0 bg-gradient-to-t from-black via-white/10 to-black'></div>
        <SwitchVideo
          mobile={mobile}
          desktop={desktop}
        />
        <div className='absolute inset-0 flex flex-col justify-center items-center w-full md:w-1/2 mx-auto px-2 gap-4'>
          <div className='w-16 sm:w-20'>
            <img
              className='w-full h-full object-cover'
              alt='logoImg'
              src={logo}
            />
          </div>
          <h1 className='gold text-4xl md:text-6xl border-b-[1px] border-yellow-50 pb-1'>
            YOUPRINCESS
          </h1>
          <h1 className='text-center indent-8 text-lg md:text-2xl text-yellow-50'>
            Здравствуйте, дорогие девушки - мы являемся одним из ведущих
            агентств по Эскорту в Сербии! C нами максимально комфортно работать,
            ведь наши операторы имеют огромный опыт. В отличие от других
            агентств, мы прислушиваемся к мнению девушек и хотим работать в
            симбиозе, а не только гнуть свою линию. Все наши модели остаются
            довольны и возвращаются к нам снова и снова. Мы работаем почти со
            всеми типажами и любой возрастной категорией, ведь каждая девочка
            для нас прежде всего личность
          </h1>
          <div className='flex flex-col sm:flex-row items-center justify-center gap-6 sm:gap-4'>
            <h1>
              <Link
                to={'https://t.me/SerbianWork'}
                className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
              >
                Связаться с нами в Telegram
              </Link>
            </h1>
            <h1>
              <Link
                to={
                  'https://api.whatsapp.com/send/?phone=%2B79810287364&text=%D0%AF+%D0%BE%D1%82+YOUPRINCESS.+%D0%A5%D0%BE%D1%87%D1%83+%D0%BA+%D0%92%D0%B0%D0%BC+%D0%B2+%D1%82%D1%83%D1%80&type=phone_number&app_absent=0'
                }
                className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
              >
                Связаться с нами в Whatsapp
              </Link>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}
