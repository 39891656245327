import SwitchVideo from '../hooks/videoSwitcher'
import mobile from '../assets/videos/heroMob1.mp4'
import desktop from '../assets/videos/heroDesktop5.mp4'
import MainBanner from './MainBanner'

export default function HeroVideo() {
  return (
    <section className='w-full h-screen'>
      <div className='w-full h-full relative'>
        <div className='absolute inset-0 bg-gradient-to-t from-black via-white/10 to-white-10'></div>
        <SwitchVideo
          mobile={mobile}
          desktop={desktop}
        />
        <MainBanner />
      </div>
    </section>
  )
}
