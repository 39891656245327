import logo from '../assets/images/crown_sm.png'

export default function Navbar() {
  return (
    <header className='bg-white/10 backdrop-blur-md w-full flex flex-col justify-center items-center fixed z-50 top-0  shadow-lg'>
      <div className='flex items-end gap-2 text-xl font-bold border-b-[1px] border-yellow-50 gold'>
        <h1>Y</h1>
        <div className='w-16 sm:w-20'>
          <img
            className='w-full h-full object-cover'
            alt='logoImg'
            src={logo}
          />
        </div>
        <h1>P</h1>
      </div>
      <h1 className='text-sm sm:text-lg gold'>YOUPRINCESS</h1>
    </header>
  )
}
