import desktopImage1 from '../assets/images/benefitsGrid/benefitDesktopMd1.png'
import desktopImage2 from '../assets/images/benefitsGrid/benefitDesktopMd2.png'
import desktopImage3 from '../assets/images/benefitsGrid/benefitDesktopMd3.png'
import desktopImage4 from '../assets/images/benefitsGrid/benefitDesktopMd4.png'
import desktopImage5 from '../assets/images/benefitsGrid/benefitDesktopMd5.png'
import desktopImage6 from '../assets/images/benefitsGrid/benefitDesktopMd6.png'

import mobileImage1 from '../assets/images/benefitsGrid/benefitMobile1.png'
import mobileImage2 from '../assets/images/benefitsGrid/benefitMobile2.png'
import mobileImage3 from '../assets/images/benefitsGrid/benefitMobile3.png'
import mobileImage4 from '../assets/images/benefitsGrid/benefitMobile4.png'
import mobileImage5 from '../assets/images/benefitsGrid/benefitMobile5.png'
import mobileImage6 from '../assets/images/benefitsGrid/benefitMobile6.png'

export const benefitsGrid = [
  {
    title: 'ПРОЖИВАНИЕ',
    text: 'Отличные квартиры в центре города с очень хорошими условиями',
    desktopImage: desktopImage1,
    mobileImage: mobileImage1,
  },
  {
    title: 'БЕЗОПАСНОСТЬ',
    text: 'Мы заботятимся о своих сотрудницах и обеспечиваем максимальную безопасность во время работы',
    desktopImage: desktopImage2,
    mobileImage: mobileImage2,
  },
  {
    title: 'ПРИВАТНОСТЬ',
    text: 'Девушки могут быть уверены в том, что их личные данные и информация о работе будут храниться в тайне',
    desktopImage: desktopImage3,
    mobileImage: mobileImage3,
  },
  {
    title: 'СОПРОВОЖДЕНИЕ',
    text: 'Каждой девушке на время тура закрепляем оператора, который вам всегда и все подскажет. Мы стараемся работать не только на количество, нам важно еще и качество',
    desktopImage: desktopImage4,
    mobileImage: mobileImage4,
  },
  {
    title: 'ОПЛАТА',
    text: 'Приятная ценовая политика работа 50/50 - 30.мин или один шот 100-120 евро, 60мин. два шота 150-200 евро, выезд (такси оплачивает гость) 250-300евро',
    desktopImage: desktopImage5,
    mobileImage: mobileImage5,
  },
  {
    title: 'ВЕСЬ МИР',
    text: 'Свободный график работы, что позволяет планировать свое время самостоятельно и сочетать работу с другими интересами или занятиями',
    desktopImage: desktopImage6,
    mobileImage: mobileImage6,
  },
]
