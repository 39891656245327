import SwitchImage from '../hooks/imageSwitcher'
import mobileImage from '../assets/images/heroMobile3.png'
import desktopImage from '../assets/images/heroDesktop3.png'
import { Link } from 'react-router-dom'

export default function HeroSection() {
  return (
    <section className='w-full h-full py-20 sm:py-32'>
      <div className='w-full sm:w-3/4 h-full flex flex-col lg:flex-row mx-auto items-center gap-4'>
        <div className='w-3full lg:w-1/3 text-white text-center flex flex-col justify-center items-center gap-2 py-4 px-2'>
          <h1 className='text-2xl sm:text-4xl text-center gold border-b-[1px] border-yellow-50 font-bold'>
            YOUPRINCESS
          </h1>
          <h1 className='text-md lg:text-lg indent-8 text-center text-yellow-50 font-bold'>
            Работа в эскорте Сербии - это прежде всего достойная ценовая
            политика, где в отличии от других стран не роняют ценник. Ведь
            Белград доступен для многих стран без визы. Поэтому поток туристов
            идет постоянный и не прекращается круглый год. Уровень жизни
            постоянно растет, а значит все больше появляется людей - готовых
            отдать Вам милые дамы все, что вы захотите! ДОБРО ПОЖАЛОВАТЬ В
            СЕРБИЮ, НАШЕ АГЕНСТВО YouPrincess и конечно же нашу команду!
          </h1>
          <h1 className='pt-2'>
            <Link
              to={'https://t.me/SerbianWork'}
              className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
            >
              Связаться с нами
            </Link>
          </h1>
        </div>
        <div className='w-full lg:w-2/3 relative'>
          <div className='absolute hidden lg:block inset-0 bg-gradient-to-r from-black via-white/10 to-white/10'></div>
          <SwitchImage
            style={`w-full h-full bg-black object-cover`}
            mobile={mobileImage}
            desktop={desktopImage}
          />
        </div>
      </div>
    </section>
  )
}
