import SwitchImage from '../hooks/imageSwitcher'
import mobile from '../assets/images/howBanner/mobile.png'
import desktop from '../assets/images/howBanner/desktop.png'
import logo from '../assets/images/crown_sm.png'
import { Link } from 'react-router-dom'

export default function HowToBanner() {
  return (
    <section className='w-full h-full py-12'>
      <div className='flex flex-col items-center justify-center gap-2 w-full px-2 pb-8 font-bold'>
        <div className='w-12'>
          <img
            className='w-full h-full object-cover'
            alt='logoImg'
            src={logo}
          />
        </div>
        <h5 className='gold'>YOUPRINCESS</h5>
        <h1 className='text-3xl font-medium drop-shadow-lg text-center text-yellow-50'>
          КАК МЫ РАБОТАЕМ
        </h1>
      </div>
      <div className='relative w-full h-full'>
        <SwitchImage
          style={`w-full h-full bg-black object-cover`}
          mobile={mobile}
          desktop={desktop}
        />
        <div className='absolute inset-0 bg-gradient-to-t from-black via-white/10 to-black'></div>
        <div className='absolute inset-0 flex flex-col items-center justify-center text-center gap-12'>
          <h1 className='text-yellow-50 w-5/6 px-2 lg:w-1/2 text-md md:text-lg lg:text-4xl indent-8 font-bold drop-shadow-md'>
            С момента заключения устного договора(условно), мы берем на себя
            максимальную ответственность по вашей безопастности. Наши операторы
            тщательно отслеживают каждого гостя, даже если он является
            постоянным. В отличие от многих других агентств, мы стараемся
            обеспечить не мнимую, а максимальную защиту на протяжение всего
            нашего сотрудничества
          </h1>
          <div className='flex flex-col sm:flex-row items-center justify-center gap-6 sm:gap-4'>
            <h1>
              <Link
                to={'https://t.me/SerbianWork'}
                className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
              >
                Связаться с нами в Телеграм
              </Link>
            </h1>
            <h1>
              <Link
                to={
                  'https://api.whatsapp.com/send/?phone=%2B79810287364&text=%D0%AF+%D0%BE%D1%82+YOUPRINCESS.+%D0%A5%D0%BE%D1%87%D1%83+%D0%BA+%D0%92%D0%B0%D0%BC+%D0%B2+%D1%82%D1%83%D1%80&type=phone_number&app_absent=0'
                }
                className='w-3/4 goldbg rounded-full px-4 py-2 font-bold'
              >
                Связаться с нами в Воцап
              </Link>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}
